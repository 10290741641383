import { FeedBackForm } from '@src/components/FeedbackForm'
import { Page } from '@src/components/Page'
import { Block, Button, Flexbox, Header, Paragraph, modal } from '@stage-ui/core'

const S04ThatsIt = () => {
  return (
    <Block backgroundColor="onSurface">
      <Page py="7rem">
        <Flexbox
          column
          justifySelf="center"
          alignItems={['flex-start', 'flex-start', 'center']}
        >
          <Header
            align={['start', 'start', 'center']}
            weight={800}
            size="3.125rem"
            mb="m"
            color="surface"
            lineHeight={1.1}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {`That's It.\nSimple as that.`}
          </Header>

          <Flexbox
            mt="3rem"
            direction={['row', 'row', 'row', 'column']}
            alignItems={['flex-end', 'flex-end', 'center']}
            alignSelf="stretch"
          >
            <Button
              w="16rem"
              mt={['xl', '*', '*', 'l']}
              label="Sign up now"
              onClick={() => {
                window.location.href = '/~'
              }}
            />
            <Button
              ml={['m', '*', '*', 0]}
              w="16rem"
              mt={['xl', '*', 'l']}
              decoration="outline"
              color="surface"
              borderColor="gray800"
              label="Contact Us"
              onClick={() => {
                modal({
                  hideHeader: true,
                  overlayClose: false,
                  p: '0',
                  render: (close) => <FeedBackForm close={close} />,
                })
              }}
            />
            <Flexbox
              column
              mt="m"
              mb="s"
              flex={1}
              alignItems={['flex-end', 'flex-end', 'flex-end', 'center']}
            >
              <Paragraph mt="m" weight={700} color="gray500">
                © ScriptHeads LLC
              </Paragraph>
              <Paragraph color="gray500">World</Paragraph>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Page>
    </Block>
  )
}

export default S04ThatsIt
