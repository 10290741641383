import { Button, Flexbox, Paragraph, TextField } from '@stage-ui/core'
import { useState } from 'react'

export const FeedBackForm = (props: { close: () => void }) => {
  const { close } = props
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [message, setMessage] = useState('')
  const submit = (a: string, b: string, c: string) => {
    return fetch(
      `${
        window.location.origin.match('http://')
          ? 'http://127.0.0.1:4010'
          : window.location.origin
      }/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
        mutation ($name: String! $contact: String! $message: String!) {
          feedback(payload: {
            name: $name
            contact: $contact
            message: $message
          })
        }
      `,
          variables: { name: a, contact: b, message: c },
        }),
      },
    )
  }
  return (
    <Flexbox p="2rem" column w={['30rem', '*', 'unset']}>
      <Paragraph weight={600}>
        Fill out the form below with your name, email, and a brief message detailing your
        inquiry. Our dedicated customer service team will get back to you as soon as
        possible.
      </Paragraph>
      <TextField
        placeholder="Name"
        decoration="underline"
        mt="m"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        placeholder="Contact email/phone"
        decoration="underline"
        mt="m"
        value={contact}
        onChange={(e) => setContact(e.target.value)}
      />
      <TextField
        overrides={{
          input: {
            padding: 0,
          },
        }}
        placeholder="Message"
        decoration="underline"
        mt="m"
        multiline
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Flexbox mt="m" alignSelf="flex-end">
        <Button
          mr="m"
          h="3.5rem"
          decoration="outline"
          color="onSurface"
          borderColor="gray300"
          label="Cancel"
          onClick={close}
        />
        <Button
          label="Submit"
          h="3.5rem"
          disabled={!name || !contact || !message}
          onClick={async () => {
            await submit(name, contact, message)
            close()
          }}
        />
      </Flexbox>
    </Flexbox>
  )
}
